$ldTextColor: #f53604;
@mixin flex {
    display: flex;
    flex-direction: row;
}
@mixin flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
@mixin flex-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}