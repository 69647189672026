@import "../../scss/main.scss";

.f-container {
  padding: 5px 10px;
  /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
}

.tile-title {
  color: #dd4a32;
  font-size: 1.6rem;
  letter-spacing: 5px;
  font-weight: bolder;
  display: inline-block;
  padding: 20px 5px;
}

.featured-posts-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
  }
}

.content-horiz {
  @include flex-center;
  justify-content: space-between;
  padding: 0 15px;

  .menu-btn {
    padding: 10px 20px;
    color: white;
    background-color: green;
    border: 0px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}

.round-menus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  height: auto;
  align-content: center;
  justify-content: space-evenly;
  margin-bottom: 25px;

  .image-cropper {
    width: 80px;
    height: 80px;
    margin: 0px 0 40px 0;
    img {
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    span {
      display: inline-block;
      margin-top: 5px;
    }
  }
}
