.social-button-container {
    height: 20vh;
    width: 40%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media only screen and (max-width: 768px) {
        width: 80%;
    }
}