@import "../../../scss/main.scss";

/* .elementToFadeInAndOut {
    opacity: 1;
    animation: fade .7s linear;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  100% { opacity: 1 }
} */

.primary-image {
  width: 100%;
  height: 491px;
  background-image: url(https://bridge91.qodeinteractive.com/wp-content/uploads/2017/08/h1-img-1.jpg?id=664) !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 5px 10px 18px #cacaca;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 230px;
    background-size: contain;
  }
}

.p-container {
  @include flex-center;
  width: 100%;
  background-color: transparent;

  .primary-menu {
    @include flex-center;
    width: 85%;
    justify-content: space-around;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: #faf3ea;
    padding: 10px;
    margin-top: -20px;
    box-shadow: 5px 10px 18px #cacaca;
    opacity: 0.9;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .menu-item {
      /* padding: 10px 20px;
            margin: 10px 5px;
            border: 1px solid #e57982;
            background-color: white;
            border-radius: 5px; */
      padding: 10px 20px;
      margin: 10px 5px;
      border-radius: 5px;
      font-size: 1.2rem;
    }

    .search-btn {
      cursor: pointer;
      padding: 0 23px;
      border: 2px solid #303030;
      border-radius: 4px;

      color: #fff;
      border-color: #00a699;
      font-size: 15px;
      line-height: 43px;
      height: 43px;
      font-weight: 600;
      background-color: #00a699;
      letter-spacing: -0.6px;
      border-width: 1px;
      padding-left: 34px;
      padding-right: 34px;
    }
    .search-btn:hover {
      transition: color 0.1s linear, background-color 0.1s linear,
        border-color 0.1s linear;

      color: #00a699;
      border-color: #00a699;
      background-color: #ffffff;
    }
  }
}
