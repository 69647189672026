.App {
  text-align: center;
}

h1,h2,h3,h4,h5,h6 {
  margin: 2px;
}

hr {
  width: 100%;
  height: 1px;
}

.App-link {
  color: #00c6fd;
}

a {
  color: #00c6fd;
  text-decoration: none;
}