.cart-item {
  padding: 7px;
  margin-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-color: lightgray;
  border-bottom-style: solid;
  margin-bottom: 5px;
}
.cart-item-image {
  .cart-item-image-class {
    height: 110px;
    width: 110px;
    @media only screen and (max-width: 768px) {
      width: 50px;
      height: 50px;
    }
  }
}
.cart-item-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item-title {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: left;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
}
.cart-item-price {
  width: 80%;
  text-align: right;
  font-size: 16px;
}
.calc-props {
  padding: 25px;
  font-size: 20px;
  text-align: right;
  tr:nth-child(6) {
    td {
      font-size: 24px;
    }
  }
  tr:last-child {
    td {
      font-size: 32px;
    }
  }
}
