@import "../../../../scss/main.scss";
.booking-btn {
  padding: 10px 20px;
  border: 1px solid lightgray;
  border-radius: 10px;
  cursor: pointer;
}
.est-view-container {
  @include flex-start;
  width: 100%;
  text-align: left;
  flex-direction: column;

  .est-summary {
    @include flex-center;
    width: 100%;
    padding: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

    @media only screen and (max-width: 768px) {
      // flex-direction: column;
    }

    .image {
      @include flex;
      width: 100%;
      align-items: flex-start;
      img {
        height: 150px;
        width: 200px;
      }
    }
  }
  .rating-col {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    height: 150px;
    justify-content: space-between;
    align-items: center;

    .timings {
      display: inline-flex;
      height: 25px;
      line-height: 25px;
      padding-top: 22px;
      @media only screen and (max-width: 768px) {
        padding-top: 10px;
      }
    }
  }
  .est-props {
    width: 100%;
    padding: 0 10px;
  }
  .est-reviews {
    @include flex-center;
    padding: 15px;
  }
  .actions {
    @include flex-center;
    padding: 15px;
  }

  .est-description {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    padding: 10px;
    font-size: 16px;
    text-align: left;
  }

  .list-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }

  .est-products-list {
    @include flex-center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: flex-start;

    .est-prodlist {
      @include flex-center;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      align-content: flex-start;
      width: 100%;
      padding: 10px;
    }
    .prod-short-view {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      align-content: flex-start;
      width: 100%;
      font-size: 16px;
      border-bottom-width: 1px;
      border-bottom-color: lightgray;
      border-bottom-style: solid;
      margin-bottom: 10px;

      img {
        height: 100px;
        width: 170px;
        @media only screen and (max-width: 768px) {
          height: 75px;
          width: 85px;
        }
      }
    }
    .prod-short-view-props {
      @include flex-center;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 25px;
      width: 100%;
      text-align: left;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }

      .item-desc {
        font-size: 14px !important;
      }
      .item-price {
        font-size: 16px !important;
      }
    }
  }

  .est-footer {
    @include flex-center;
    width: 100%;
    justify-content: flex-start;
    padding: 10px;
  }
}

.rating-readonly {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 10px;
}
.pb {
  width: 100%;
  height: 60px;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
